import React from "react";
import DeleteGroup from './DeleteGroup';

interface Group {
  name: string;
  description: string;
  id: string;
}

interface OwnProps {
  group: Group;
  deleteGroup: (group: Group) => void;
}

type Props = OwnProps;

const GroupSummary: React.FC<Props> = ({ group, deleteGroup }) => {
  return (
    <tr className="hover:bg-gray-100 transition duration-200">
      <td className="py-2 px-4 border-b">{group.name}</td>
      <td className="py-2 px-4 border-b">{group.description}</td>
      <td className="py-2 px-4 border-b">
        <div className="flex">
          <DeleteGroup group={group} deleteGroup={deleteGroup} />
        </div>
      </td>
    </tr>
  );
};

export default React.memo(GroupSummary);
