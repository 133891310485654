import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Group {
  id: string;
  name: string;
  description: string;
  users?: string[];
}

export interface GroupsState {
  message?: string;
  list: {[id: string] : Group};
  groupError?: string;
}

const initialState: GroupsState = {
  message: '',
  groupError: '',
  list: {}
};

const groupsReducer = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroupError(state, action: PayloadAction<string>) {
      state.groupError = action.payload;
    },
    setGroups(state, action: PayloadAction<any>) {
      state.list = action.payload;
    },
    setGroup(state, action: PayloadAction<Group>) {
      if (state.list) {
        state.list = {...state.list, [action.payload.id]: action.payload};
      } else {
        state.list = {[action.payload.id]:action.payload};
      }
    },
    setGroupMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
  }
});

export const { setGroupError, setGroups, setGroup, setGroupMessage } = groupsReducer.actions;
export default groupsReducer.reducer;