import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../../config/fbConfig";
import { setPersistence, browserSessionPersistence, signOut as firebaseSignOut, onAuthStateChanged, EmailAuthProvider, reauthenticateWithCredential, updatePassword, signInWithEmailAndPassword } from 'firebase/auth';
import { setUser, setCategory, setAuthError, setResetError, AuthState, clearErrors } from '../reducers/authReducer';

export const stateChange = createAsyncThunk('auth/stateChange', async (_, { dispatch }) => {
  onAuthStateChanged(auth, async user => {
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      dispatch(setUser({ uid: user.uid, name: user.displayName || '', email: user.email || '' }));
      dispatch(setCategory(idTokenResult.claims.category as string));
    } else {
      dispatch(setCategory('LOGIN'));
      dispatch(setUser(undefined));
    }
  });
});

export const updateUserPassword = createAsyncThunk(
  'auth/updateUserPassword',
  async ({ password, newPassword }: { password: string; newPassword: string }, { getState, dispatch }) => {
    const state = getState() as { auth: AuthState };
    const credential = EmailAuthProvider.credential(state.auth.user!.email, password);
    if(!state.auth.user) return;
    await reauthenticateWithCredential(state.auth.user!, credential);
    await updatePassword(state.auth.user!, newPassword);
    dispatch(setAuthError(undefined));
  }
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ email, password, captcha }: { email: string; password: string; captcha: string }, { dispatch }) => {
    try {
      const verifyRecaptcha = httpsCallable(functions, 'verifyRecaptcha');
      const result = await verifyRecaptcha({ token: captcha });
      if ((result.data as any).success) {
        await setPersistence(auth, browserSessionPersistence);
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const idTokenResult = await userCredential.user.getIdTokenResult();
        dispatch(setUser({ uid: userCredential.user.uid, name: userCredential.user.displayName || '', email: userCredential.user.email || '' }));
        dispatch(setCategory(idTokenResult.claims.category as string));
      } else {
        dispatch(setAuthError('reCAPTCHA verification failed'));
      }
    } catch (error) {
      dispatch(setAuthError('Invalid Credentials'));
    }
  }
);

export const reset = createAsyncThunk(
  'auth/reset',
  async (email: string, { dispatch }) => {
    try {
      const resetPassword = httpsCallable(functions, 'resetPassword');
      await resetPassword({ email });
      dispatch(setResetError(undefined));
    } catch (error) {
      dispatch(setResetError('Reset Limit Exceeded, Contact Admin'));
    }
  }
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (_, { dispatch }) => {
    await firebaseSignOut(auth);
    dispatch(setCategory('LOGIN'));
    dispatch(setUser(undefined));
  }
);

export const clearError = createAsyncThunk(
  'auth/clearError',
  async (_, { dispatch }) => {
    dispatch(clearErrors());
  }
);  