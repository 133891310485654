import React from 'react';

const LoadingScreen: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-900 text-green-400">
      <div className="mb-5">
        <div className="w-16 h-20 relative animate-spin">
          <div className="w-full h-full bg-green-400 clip-shield shadow-lg"></div>
        </div>
      </div>
      <h2 className="font-sans text-lg">Loading Secure Environment...</h2>
    </div>
  );
};

export default LoadingScreen;
