import React, { useState } from 'react';
// @ts-ignore
import { TrashIcon } from '@heroicons/react/24/outline'; // Importing the Trash Icon from Heroicons

interface Group {
  name: string;
  description: string;
  id: string;
}

interface OwnProps {
  group: Group;
  deleteGroup: (group: Group) => void;
}

type Props = OwnProps;

const DeleteGroup: React.FC<Props> = ({ group, deleteGroup }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className="text-red-500 hover:text-red-700 rounded-full focus:outline-none"
        onClick={handleShow}
      >
        <TrashIcon className="h-6 w-6" /> {/* Using Heroicons Trash Icon */}
      </button>

      {/* Tailwind CSS Responsive Modal */}
      {show && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50 p-4 sm:p-6">
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg w-full sm:w-1/2 md:w-1/3 max-w-lg">
            <h2 className="text-lg font-bold mb-4">Delete Group?</h2>
            <p><span className="font-semibold">Name:</span> {group.name}</p>
            <p><span className="font-semibold">Description:</span> {group.description}</p>
            <div className="flex justify-end mt-4 space-x-2">
              <button
                className="bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                className="bg-red-500 text-white font-bold py-2 px-4 rounded"
                onClick={() => { deleteGroup(group); handleClose(); }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(DeleteGroup);
