import React from "react";
import DeleteUser from './DeleteUser';
import EditUser from "./EditUser";

interface UserStatus {
  completed?: boolean;
  retake?: boolean;
  score?: { [key: string]: number };
  currentModule: number;
}

interface User {
  email: string;
  firstName: string;
  lastName: string;
  managerEmail: string;
  startDate: string;
  uid: string;
  status?: { [key: string]: UserStatus };
}

interface OwnProps {
  user: User;
  deleteUser: (user: any) => void;
  updateUser: (user: User) => void;
}

type Props = OwnProps;

const UserSummary: React.FC<Props> = ({ user, deleteUser, updateUser }) => {

  const getProgressBarVariant = () => {
    if (user.status?.cyberSecurity?.completed) return 'bg-green-500';
    if (user.status?.cyberSecurity?.currentModule) return 'bg-yellow-500';
    return 'bg-gray-300'; // Default to gray for not started
  };
  
  const getProgressBarValue = () => {
    if (user.status?.cyberSecurity?.completed) {
      return 100;
    } else {
      const module = user.status?.cyberSecurity?.currentModule || 0;
      return parseFloat(((module / 12) * 100).toFixed(2));
    }
  };

  const getModuleText = () => {
    if (user.status?.cyberSecurity) {
      return user.status.cyberSecurity.completed ? '12/12' : `${user.status.cyberSecurity.currentModule || 0}/12`;
    }
    return '0/12';
  };

  return (
    <tr className={`align-left`}>
      <td className="sm:hidden">
        <strong>{user.firstName} {user.lastName}</strong><br />
        <small>{user.email}</small>
      </td>
      <td className="p-2 hidden sm:table-cell text-left">{user.email}</td>
      <td className="p-2 hidden md:table-cell text-left">{user.firstName}</td>
      <td className="p-2 hidden md:table-cell text-left">{user.lastName}</td>
      <td className="p-2 hidden lg:table-cell text-left">{user.managerEmail}</td>
      <td className="p-2 hidden lg:table-cell text-left">{user.startDate}</td>
      <td>
      <div className="flex flex-col items-center">
  <span className="mb-2 text-sm font-semibold text-gray-700">{getModuleText()}</span>
  <div className="relative w-full h-5 bg-gray-200 rounded-full overflow-hidden">
    <div
      className={`${getProgressBarVariant()} h-full rounded-full transition-all duration-300 ease-in-out`}
      style={{ width: `${getProgressBarValue()}%` }}
    />
    <div
      className="absolute inset-0 flex items-center justify-center text-xs font-bold text-white"
      style={{ width: `${getProgressBarValue()}%` }}
    >

    </div>
  </div>
</div>
      </td>
      <td className="flex space-x-2 text-center">
        <EditUser user={user} updateUser={updateUser} />
        <DeleteUser user={user} deleteUser={deleteUser} />
      </td>
    </tr>
  );
}

export default UserSummary;
