import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { setStatus, getStatus, getCourse } from "../../store/actions/courseActions";
import VideoPage from "./VideoPage";
import QuizPage from "./QuizPage";
import { AppDispatch } from "../../store";

interface AuthState {
  user: {
    uid: string;
  };
}

interface CourseState {
  list: {
    [key: string]: {
      modules: {
        [key: number]: {
          type: string;
          title: string;
        };
      };
    };
  };
  status: {
    currentModule: number;
    score: {
      [key: number]: number;
    };
  };
}

interface AppState {
  auth: AuthState;
  courses: CourseState;
}

interface DispatchProps {
  setStatus: (uid: string, id: string, status: any) => void;
  getStatus: (uid: string, id: string) => void;
  getCourse: (id: string) => void;
}

type Props = AppState & DispatchProps;

const CourseContent: React.FC<Props> = ({ auth, courses, getStatus, setStatus, getCourse }) => {
  const { id } = useParams<{ id: string }>();
  const courseId = id ?? '';
  const { uid } = auth.user;
  const [loading, setLoading] = useState(true);
  const [currentModule, setCurrentModule] = useState(-1);

  useEffect(() => {
    getCourse(courseId);
  }, [courseId, getCourse]);

  const increaseModuleCount = useCallback(() => {
    if (courses.status.currentModule === currentModule) {
      setStatus(uid, courseId, { ...courses.status, currentModule: currentModule + 1 });
    } else if (courses.status.currentModule === undefined) {
      setStatus(uid, courseId, { currentModule: 1 });
    }
    setCurrentModule(currentModule + 1);
  }, [currentModule, courses.status, courseId, setStatus, uid]);

  const increaseModuleWithScores = useCallback(
    (score: number) => {
      if (courses.status.currentModule === currentModule) {
        setStatus(uid, courseId, {
          ...courses.status,
          currentModule: currentModule + 1,
          score: { ...courses.status.score, [currentModule]: score },
        });
      }
      setCurrentModule(currentModule + 1);
    },
    [currentModule, courses.status, courseId, setStatus, uid]
  );

  useEffect(() => {
    if (currentModule >= 0 && courses.list && courses.list[courseId] && Object.keys(courses.list[courseId].modules).length > 0) {
      setLoading(false);
    }
  }, [currentModule, courses.list, courseId]);

  useEffect(() => {
    if (courses.status) {
      setCurrentModule(courses.status.currentModule ?? 0);
    } else {
      getStatus(uid, courseId);
    }
  }, [courses.status, getStatus, courseId, uid]);

  if (!loading) {
    return (
      <div className="container mx-auto p-4">
        <div className="flex flex-col md:flex-row">
          {/* Sidebar for Modules */}
          <div className="w-full md:w-1/4 md:order-2 mb-4 md:mb-0">
            <div className="bg-white shadow-md rounded p-4">
              <ul className="space-y-2">
                {Object.keys(courses.list[courseId].modules).map((module, i) => (
                  <li
                    key={i}
                    className={`cursor-pointer p-2 rounded text-center text-sm md:text-base ${
                      i === currentModule ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-800'
                    } ${courses.status.currentModule && i > courses.status.currentModule ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => setCurrentModule(i)}
                  >
                    {courses.list[courseId].modules[i].title}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Main Content */}
          <div className="w-full md:w-3/4 md:order-1 mt-4 md:mt-0">
            {courses.list[courseId].modules[currentModule].type === "video" ? (
              <VideoPage increaseCount={increaseModuleCount} count={currentModule} />
            ) : (
              <QuizPage increaseCountWithScore={increaseModuleWithScores} count={currentModule} />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-16 h-16 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
        <span className="ml-4 text-xl">Loading...</span>
      </div>
    );
  }
};

const mapStateToProps = (state: AppState) => state;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setStatus: (uid: string, id: string, status: any) => dispatch(setStatus({ uid, id, status })),
  getStatus: (uid: string, id: string) => dispatch(getStatus({ uid, id })),
  getCourse: (id: string) => dispatch(getCourse(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseContent);
