import { Link } from "react-router-dom";

const links = [
  { to: '/', label: 'Dashboard' },
  { to: '/users', label: 'Users' },
  { to: '/courses', label: 'Courses' },
  { to: '/reports', label: 'Reports' }
];

const AdminLinks: React.FC = () => {
  return (
    <> {/* Changed from flex-col to flex and added space-x-4 */}
      {links.map((link, index) => (
        <Link 
          to={link.to} 
          key={index} 
          className="text-gray-700 hover:text-sky-600 transition duration-200 py-2 px-4 rounded-md hover:bg-gray-100"
        >
          {link.label}
        </Link>
      ))}
    </>
  );
};

export default AdminLinks;
