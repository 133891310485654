import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createUser, batchCreateUsers, clearUserMessage, fetchUsers } from '../../store/actions/usersActions';
import { useNavigate } from 'react-router-dom';
import { fetchGroups, fetchGroup } from '../../store/actions/groupsActions';
import { AppDispatch } from '../../store';

interface AppState {
  users: any;
  auth: any;
  groups: any;
}

interface StateProps {
  users: any;
  auth: any;
  groups: any;
}

interface DispatchProps {
  createUser: (user: any) => void;
  batchCreateUsers: (importUsers: any, gid: string) => void;
  clearMessage: () => void;
  fetchUsers: (search: string, status: string) => void;
  fetchGroups: () => void;
  fetchGroup: (id: string) => void;
}

type Props = StateProps & DispatchProps;

const CreateUser: React.FC<Props> = ({
  users,
  fetchUsers,
  auth,
  fetchGroups,
  fetchGroup,
  clearMessage,
  createUser,
  batchCreateUsers,
  groups,
}) => {
  const [disabled, setDisabled] = useState(false);
  const { uid } = auth.user;
  const [importDisabled, setImportDisabled] = useState(false);
  const [importUsers, setImportUsers] = useState<any[]>([]);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [duplicateUser, setDuplicateUser] = useState<any>({});
  const [importGroup, setImportGroup] = useState(users?.users?.[uid]?.group || '');
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    group: users?.users?.[uid]?.group || '',
  });

  useEffect(() => {
    fetchUsers('', 'ALL');
    if (auth.category === 'SUPER_ADMIN') fetchGroups();
  }, []);

  useEffect(() => {
    if (auth.category === 'ADMIN' && users?.users?.[uid]?.group) fetchGroup(users.users[uid].group);
    setUser((prevUser) => ({
      ...prevUser,
      group: users?.users?.[uid]?.group || '',
    }));
  }, [users]);

  useEffect(() => {
    if (users.message === 'success') {
      setDisabled(false);
      setImportDisabled(false);
      clearMessage();
    }
  }, [users.message]);

  useEffect(() => {
    if (uploadFile) {
      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
        const text = event.target.result;
        csvFileToArray(text);
      };
      fileReader.readAsText(uploadFile);
    }
  }, [uploadFile]);

  useEffect(() => {
    const existingUser = Object.values(users?.users || {}).find((u: any) => u.email === user.email.trim());
    if (existingUser) {
      setMessage('User Already Exist');
      setDuplicateUser(existingUser);
    } else {
      setMessage('');
      setDuplicateUser({});
    }
  }, [user.email]);

  const csvFileToArray = (string: string) => {
    const csvHeader = ['Email', 'FirstName', 'LastName'];
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');
    const array = csvRows.map((row) => {
      const values = row.split(',');
      return csvHeader.reduce((object: any, header: string, index: number) => {
        object[header] = values[index];
        return object;
      }, {});
    });
    const existingEmails = Object.values(users?.users || {}).map((u: any) => u.email);
    const uniqueEmails = array.filter(
      (item, index, self) =>
        index === self.findIndex((t: any) => t.Email.trim() === item.Email.trim()),
    );
    setImportUsers(uniqueEmails.filter((r: any) => r.Email.trim().length > 0 && !existingEmails.includes(r.Email.trim())));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [id]: value,
    }));
  };

  const groupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setImportGroup(e.target.value);
    setUser((prevUser) => ({
      ...prevUser,
      group: e.target.value,
    }));
  };

  const reset = () => {
    setUser({
      firstName: '',
      lastName: '',
      email: '',
      group: users?.users?.[uid]?.group || '',
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setDisabled(true);
    if (message === '') {
      createUser(user);
      reset();
    } else {
      setShow(true);
    }
  };

  const handleFileAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) setUploadFile(e.target.files[0]);
  };

  const removeRecord = (item: any) => {
    setImportUsers(importUsers.filter((r) => r !== item));
  };

  const handleClose = () => {
    setShow(false);
    reset();
    setDisabled(false);
  };

  return (
    <div className="container mx-auto p-4">
      <form onSubmit={handleSubmit} className="mb-6">
        <h3 className="text-2xl font-semibold text-gray-700">Add User</h3>
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="firstName">
            First Name
          </label>
          <input
            required
            id="firstName"
            type="text"
            className="border border-gray-300 rounded-md p-2 w-full"
            onChange={handleChange}
            value={user.firstName}
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="lastName">
            Last Name
          </label>
          <input
            required
            id="lastName"
            type="text"
            className="border border-gray-300 rounded-md p-2 w-full"
            onChange={handleChange}
            value={user.lastName}
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            required
            id="email"
            type="email"
            className="border border-gray-300 rounded-md p-2 w-full"
            onChange={handleChange}
            value={user.email}
          />
        </div>
        {groups?.list && (
          <div className="mb-4">
            <label className="block font-bold mb-2" htmlFor="group">
              Group
            </label>
            <select
              required
              id="group"
              className="border border-gray-300 rounded-md p-2 w-full"
              onChange={groupChange}
              value={user.group}
            >
              <option value="">Select Group</option>
              {Object.keys(groups.list).map((gId) => (
                <option key={gId} value={gId}>
                  {groups.list[gId].name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="mb-4 flex space-x-2">
          <button
            disabled={disabled}
            className={`bg-green-500 text-white px-4 py-2 rounded-md ${
              disabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            type="submit"
          >
            {disabled ? (
              <div className="flex items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v2a6 6 0 100 12v2a8 8 0 01-8-8z"
                  />
                </svg>
                Loading...
              </div>
            ) : (
              'Invite'
            )}
          </button>
          <button
            type="button"
            className="bg-yellow-500 text-white px-4 py-2 rounded-md"
            onClick={reset}
          >
            Reset
          </button>
          <button
            type="button"
            className="bg-gray-500 text-white px-4 py-2 rounded-md"
            onClick={() => setImportDisabled((prev) => !prev)}
          >
            {importDisabled ? 'Cancel' : 'Import Users'}
          </button>
        </div>
      </form>
      {importDisabled && (
        <div className="mb-6">
          <input
            type="file"
            accept=".csv"
            className="border border-gray-300 rounded-md p-2 mb-2"
            onChange={handleFileAttachment}
          />
          {importUsers.length > 0 && (
            <table className="min-w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 p-2">Email</th>
                  <th className="border border-gray-300 p-2">First Name</th>
                  <th className="border border-gray-300 p-2">Last Name</th>
                  <th className="border border-gray-300 p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {importUsers.map((item, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 p-2">{item.Email}</td>
                    <td className="border border-gray-300 p-2">{item.FirstName}</td>
                    <td className="border border-gray-300 p-2">{item.LastName}</td>
                    <td className="border border-gray-300 p-2">
                      <button
                        onClick={() => removeRecord(item)}
                        className="bg-red-500 text-white px-2 py-1 rounded-md"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {importUsers.length > 0 && (
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2"
              onClick={() => batchCreateUsers(importUsers, importGroup)}
            >
              Import Users
            </button>
          )}
        </div>
      )}
      {message && (
        <div className="bg-red-500 text-white p-4 rounded-md mb-4">
          <p>{message}</p>
          {duplicateUser.email && (
            <p>
              Duplicate User: <strong>{duplicateUser.email}</strong>
            </p>
          )}
        </div>
      )}
      {show && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h4 className="text-lg font-semibold mb-4">Warning</h4>
            <p>{message}</p>
            <div className="flex justify-end">
              <button
                onClick={handleClose}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  users: state.users,
  auth: state.auth,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createUser: (user: any) => dispatch(createUser(user)),
  batchCreateUsers: (importUsers: any[], gid: string) => dispatch(batchCreateUsers({userList:importUsers, groupId: gid})),
  clearMessage: () => dispatch(clearUserMessage()),
  fetchUsers: (search: string, status: string) => dispatch(fetchUsers({search, status})),
  fetchGroups: () => dispatch(fetchGroups()),
  fetchGroup: (id: string) => dispatch(fetchGroup(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
