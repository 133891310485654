import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createGroup, clearMessage, fetchGroups } from '../../store/actions/groupsActions';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store';

interface AppState {
  groups: any;
}

interface StateProps {
  groups: any;
}

interface DispatchProps {
  createGroup: (group: Group) => void;
  clearMessage: () => void;
  fetchGroups: () => void;
}

interface Group {
  id: string;
  name: string;
  description: string;
  users?: string[];
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const CreateGroup: React.FC<Props> = ({ groups, clearMessage, fetchGroups, createGroup }) => {
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [duplicateGroup, setDuplicateGroup] = useState<Group>({ name: '', description: '', id: '' });
  const [group, setGroup] = useState<Group>({ name: '', description: '', id: '' });

  const handleClose = () => {
    setShow(false);
    reset();
    setDisabled(false);
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (groups.list && Object.keys(groups.list).some(id => groups.list[id].name === group.name.trim())) {
      setMessage('Group Already Exists');
      setDuplicateGroup(groups.list[Object.keys(groups.list).find(id => groups.list[id].name === group.name.trim())!]);
    } else {
      setMessage('');
      setDuplicateGroup({ name: '', description: '', id: '' });
    }
  }, [group.name, groups.list]);

  useEffect(() => {
    if (groups?.message === 'Group created successfully') {
      setDisabled(false);
      clearMessage();
    }
  }, [groups, clearMessage]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setGroup(prevGroup => ({ ...prevGroup, [id]: value }));
  };

  const reset = () => {
    setGroup({ name: '', description: '', id: '' });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    if (message === '') {
      createGroup(group);
      reset();
    } else {
      handleShow();
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h3 className="text-gray-800 font-bold mb-4">Add Group</h3>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name</label>
          <input
            id="name"
            required
            onChange={handleChange}
            value={group.name}
            className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700 font-bold mb-2">Description</label>
          <input
            id="description"
            required
            onChange={handleChange}
            value={group.description}
            className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>
        <div className="flex">
          <button
            disabled={disabled}
            type="submit"
            className={`bg-green-500 text-white font-bold py-2 px-4 mr-2 rounded-lg focus:outline-none ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {disabled ? <span>Loading...</span> : 'Create'}
          </button>
          <button
            type="button"
            onClick={reset}
            className="bg-yellow-500 text-white font-bold py-2 px-4 mr-2 rounded-lg focus:outline-none"
          >
            Reset
          </button>
          <button
            type="button"
            onClick={() => navigate('/groups')}
            className="bg-gray-500 text-white font-bold py-2 px-4 mr-2 rounded-lg focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </form>

      {/* Modal for duplicate group message */}
      {show && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="font-bold text-lg">{message}</h2>
            <p>Name: {duplicateGroup.name}</p>
            <p>Description: {duplicateGroup.description}</p>
            <div className="flex justify-end mt-4">
              <button
                className="bg-blue-500 text-white font-bold py-1 px-4 rounded-lg focus:outline-none"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createGroup: (group: Group) => dispatch(createGroup(group)),
  clearMessage: () => dispatch(clearMessage()),
  fetchGroups: () => dispatch(fetchGroups()),
});

const mapStateToProps = (state: AppState) => ({
  groups: state.groups,
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(React.memo(CreateGroup));
