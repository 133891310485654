import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDownloadURL, ref as storageRef, uploadBytes } from "firebase/storage";
import { storage, db } from "../../config/fbConfig";
import { ref, child, set, onValue, push } from 'firebase/database';
import { setUrl, setStatusRecord, setList } from '../reducers/courseReducer';

const usersRef = child(ref(db), 'users');

export const getVideoURL = createAsyncThunk('course/getVideoURL', async (video: string, { dispatch }) => {
  const extensions = ['webm', 'mp4'];
  const urls: { [key: string]: string } = {};

  const promises = extensions.map(async (ext) => {
    const url = await getDownloadURL(storageRef(storage, `videos/${video}.${ext}`));
    urls[ext] = url;
  });

  await Promise.all(promises);
  dispatch(setUrl(urls));
});

export const setStatus = createAsyncThunk('course/setStatus', async ({ uid, id, status }: { uid: string, id: string, status: any }, { dispatch }) => {
  const userRef = child(usersRef, uid);
  
  await set(child(userRef, `status/${id}`), status);
  dispatch(setStatusRecord({...status}));
});

export const getStatus = createAsyncThunk('course/getStatus', async ({ uid, id }: { uid: string, id: string }, { dispatch }) => {
  const userRef = child(usersRef, uid);
  onValue(child(userRef, `status/${id}`), (snapshot) => {
    
    const status = snapshot.exists() ? snapshot.val() : { currentModule: 0 };
    dispatch(setStatusRecord({...status }));
  });
});

export const getCourses = createAsyncThunk('course/getCourses', async (search: string, { dispatch }) => {
  const coursesRef = child(ref(db), 'courses');
  onValue(coursesRef, (snapshot) => {
    const courses = snapshot.exists() ? snapshot.val() : {};
    const filteredCourses = Object.fromEntries(
      Object.entries(courses).filter(([id, val]: [string, any]) =>
        val.name.toLowerCase().includes(search.toLowerCase())
      )
    );
    dispatch(setList(filteredCourses));
  });
});

export const getCourse = createAsyncThunk('course/getCourse', async (id: string, { dispatch }) => {
  const courseRef = child(ref(db), `courses/${id}`);
  onValue(courseRef, (snapshot) => {
    const course = snapshot.exists() ? snapshot.val() : {};
    dispatch(setList({ [id]: course }));
  });
});

export const createCourse = createAsyncThunk('course/createCourse', async (course: any, { dispatch }) => {
  const moduleList: any[] = [];

  const uploadFiles = async (course: any) => {
    const { files } = course;
    const courseStorageRef = storageRef(storage, `Courses/${course.id}`);
    const promises = files.map((file: File) => {
      const fileRef = storageRef(courseStorageRef, file.name);
      moduleList.push({ title: file.name, fileName: fileRef.name, type: 'video' });
      return uploadBytes(fileRef, file);
    });
    await Promise.all(promises);
  };

  const coursesRef = child(ref(db), 'courses');
  const objRef = await push(coursesRef, course);
  if (objRef.key) {
    await set(child(coursesRef, `${objRef.key}/id`), objRef.key);
    await uploadFiles({ ...course, id: objRef.key });
    await set(child(coursesRef, objRef.key), { ...course, modules: moduleList });
    dispatch(setList({ [objRef.key]: { ...course, modules: moduleList } }));
  }
});