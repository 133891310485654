import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset, clearError } from '../../store/actions/authActions';
import { useNavigate } from 'react-router-dom';
import bg from '../../assets/bg.jpg';
import logo from '../../assets/Logo.png';
import { AppDispatch, RootState } from '../../store';

const Reset: React.FC = () => {
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { resetError } = useSelector((state: RootState) => state.auth);
  const [error, setError] = useState(resetError);

  useEffect(() => {
    setError(resetError);
    if (resetError) {
      setDisabled(false);
    }
  }, [resetError]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(clearError());
    dispatch(reset(email));
    setDisabled(false);
    setEmail('');
  };

  return (
    <div className="flex w-full h-screen">
      <div className="hidden md:flex w-1/2">
        <img src={bg} alt="background" className="w-full h-full object-cover" />
      </div>
      <div className="flex w-full md:w-1/2 items-center justify-center p-6 bg-white">
        <div className="text-center max-w-xs w-full">
          <img src={logo} alt="logo" className="mb-4 w-24 h-24 mx-auto" />
          <h3 className="text-2xl font-bold mb-4 text-gray-700">Reset Password</h3>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email address</label>
              <input
                type="email"
                id="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-pink-500"
                required
              />
            </div>
            {error && (
              <div className="mb-4">
                <div className="bg-red-500 text-white px-3 py-2 rounded flex justify-between items-center">
                  <span>{error}</span>
                  <button onClick={() => dispatch(clearError())} className="text-white">X</button>
                </div>
              </div>
            )}
            <button
              type="submit"
              disabled={disabled}
              className="w-full bg-blue-500 text-white py-2 rounded font-semibold hover:bg-blue-600 transition duration-200"
            >
              {disabled ? <span className="spinner-border spinner-border-sm"></span> : 'Reset'}
            </button>
            <button
              type="button"
              onClick={() => navigate('/signin')}
              className="w-full bg-gray-200 text-blue-500 py-2 rounded mt-2 hover:bg-gray-300"
            >
              Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Reset;
