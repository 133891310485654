import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  category: string;
  user?: any;
  authError?: string;
  resetError?: string;
}

const initialState: AuthState = {
  category: 'LOGIN',
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCategory(state, action: PayloadAction<string>) {
      state.category = action.payload;
    },
    setUser(state, action: PayloadAction<any>) {
      state.user = action.payload;
    },
    setAuthError(state, action: PayloadAction<string | undefined>) {
      state.authError = action.payload;
    },
    setResetError(state, action: PayloadAction<string | undefined>) {
      state.resetError = action.payload;
    },
    clearErrors(state) {
      state.authError = undefined;
      state.resetError = undefined;
    },
  },
});

export const { setCategory, setUser, setAuthError, setResetError, clearErrors } = authReducer.actions;
export default authReducer.reducer;