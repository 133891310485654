import { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { getVideoURL } from "../../store/actions/courseActions";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../store";

interface AppState {
  auth: any;
  courses: any;
  users: any;
}

interface StateProps {
  auth: any;
  courses: any;
  users: any;
}

interface OwnProps {
  increaseCount: any;
  count: number;
}

interface DispatchProps {
  getVideoURL: (video: string) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const VideoPage: React.FC<Props> = ({ courses, count, getVideoURL, increaseCount }) => {
  const { id = '' } = useParams<{ id: string }>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const [lastTime, setLastTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState({ webm: '', mp4: '' });
  const [title, setTitle] = useState('');
  const [progress, setProgress] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (progress>99.5 && buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [progress]);

  useEffect(() => {
    if (courses?.list?.[id]?.modules) {
      setLoading(true);
      const module = courses.list[id].modules[count];
      getVideoURL(module.file);
      setTitle(module.title);
    }
  }, [count, courses?.list?.[id]?.modules, getVideoURL, id]);

  useEffect(() => {
    if (courses.url) {
      setUrl(courses.url);
      setLastTime(0);
      setProgress(0);
      setLoading(false);
    }
  }, [courses.url]);

  const handleTimeUpdate = useCallback(() => {
    if (videoRef.current) {
      if (videoRef.current.currentTime - lastTime > 1.5) {
        videoRef.current.currentTime = lastTime;
      } else {
        setLastTime(videoRef.current.currentTime ?? 0);
        setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
      }
    }
  }, [lastTime]);

  if (!loading) {
    return (
      <div className="container mx-auto text-center">
        <h3 className="text-xl font-bold">{title}</h3>
        <div className="my-4">
          <video
            autoPlay
            muted={false}
            key={url.webm}
            className="w-full h-full p-4"
            controls
            controlsList="nodownload"
            ref={videoRef}
            onTimeUpdate={handleTimeUpdate}
          >
            {isSafari ? <source src={url.mp4} type="video/mp4" /> : <source src={url.webm} type="video/webm" />}
            Your browser does not support the video tag.
          </video>
        </div>
        {count < 11 && progress > 99.8 && (
          <button
            ref={buttonRef}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 m-1"
            onClick={increaseCount}
          >
            Finish And Next
          </button>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500"></div>
      </div>
    );
  }
};

const mapStateToProps = (state: AppState) => state;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getVideoURL: (video: string) => dispatch(getVideoURL(video)),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(VideoPage);
