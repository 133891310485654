import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Question from "./Question";
import { setStatus } from "../../store/actions/courseActions";
import { fetchUser } from '../../store/actions/usersActions';
import certificate from './certificate.jpg';
import { jsPDF } from "jspdf";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../store";
import FontFaceObserver from "fontfaceobserver";
interface Auth {
  user: {
    uid: string;
  };
}

interface CourseStatus {
  completed: boolean;
  score: Record<string, string>;
  dateCompleted?: string;
  validThrough?: string;
  currentModule?: number;
}

interface Course {
  list: Record<string, {
    modules: {
      title: string;
      questions: any[];
    }[];
  }>;
  status: CourseStatus;
}

interface AppState {
  auth: Auth;
  courses: any;
  users: any;
  groups: any;
}

interface StateProps {
  auth: Auth;
  courses: any;
  user: any;
  groups: any;
}

interface OwnProps {
  increaseCountWithScore: (score: number) => void;
  count: number;
}

interface DispatchProps {
  setStatusProp: (uid: string, id: string, status: CourseStatus) => void;
  fetchUserProp: (uid: string) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const QuizPage: React.FC<Props> = ({ auth, courses, count, fetchUserProp, setStatusProp, user, groups, increaseCountWithScore }) => {
  const { id } = useParams<{ id: string }>();
  const { uid } = auth.user;
  const [name, setName] = useState('');
  const [finishDate, setFinishDate] = useState('');
  const [expiry, setExpiry] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [score, setScore] = useState(courses.status.completed ? parseFloat(courses.status.score['11']) : 0);
  const title = (id && courses.list[id]?.modules[count]?.title) ?? 'Unknown Title';
  const date = new Date();

  useEffect(() => {
    fetchUserProp(uid);
  }, [uid, fetchUserProp]);

  useEffect(() => {
    if (user) {
      setName(`${user.firstName} ${user.lastName}`);
      setFinishDate(courses.status.dateCompleted ?? '');
      setExpiry(courses.status.validThrough ?? '');
    }
  }, [user, courses.status]);

  useEffect(() => {
    if (imgUrl) {
      const pdf = new jsPDF('landscape', 'pt', 'a4');
      pdf.addImage(imgUrl, "JPEG", 20, 20, 800, 550);
      pdf.save(`${user.firstName} ${user.lastName}_${courses.list[id || ''].name}.pdf`);
    }
  }, [imgUrl]);

  const generateImage = async (name: string, startDate: string, endDate: string): Promise<void> => {
    const font = new FontFaceObserver('Italianno');
    await font.load();

    const img = new Image();
    img.src = certificate;

    await new Promise<void>((resolve, reject) => {
      img.onload = () => resolve();
      img.onerror = () => reject(new Error('Certificate image failed to load.'));
    });

    const logoSrc = await import(`../../assets/${groups.list[user.group].name.toLowerCase()}.png`);
    const logo = new Image();
    logo.src = logoSrc.default;

    await new Promise<void>((resolve, reject) => {
      logo.onload = () => resolve();
      logo.onerror = () => reject(new Error('Logo image failed to load.'));
    });

    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(img, 0, 0);

      const relativeHeight = 70;
      const relativeWidth = logo.width / logo.height * relativeHeight;
      ctx.drawImage(logo, img.width - relativeWidth - 85, img.height - relativeHeight - 45, relativeWidth, relativeHeight);

      // Apply the font only when FontFaceObserver confirms it is loaded
      ctx.font = '140px Italianno';
      ctx.fillStyle = 'darkblue';
      ctx.textAlign = 'center';
      ctx.fillText(name, canvas.width / 2, 615);

      ctx.font = '40px Arial';
      ctx.fillStyle = 'black';
      ctx.fillText(startDate, canvas.width / 4, 860);
      ctx.fillText(endDate, (canvas.width * 3) / 4, 860);
    }

    const dataUrl = canvas.toDataURL();
    setImgUrl(dataUrl);
  };  

  const handleShow = () => {
    generateImage(name, finishDate, expiry);
  };

  const finishCourse = (score: number) => {
    if (!id) {
      console.error("Course ID is undefined");
      return;
    }

    const finishDate = `${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear()}`;
    const expiry = `${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear() + 1}`;
    if (!courses.status.completed) {
      setStatusProp(uid ?? '', id ?? '', {
        ...courses.status,
        currentModule: 11,
        completed: true,
        dateCompleted: finishDate,
        validThrough: expiry,
        score: {
          ...courses.status.score,
          11: score.toString()
        }
      });
    }
    generateImage(name, finishDate, expiry);
  };

  const quizList = (id && courses.list[id]?.modules[count]?.questions) ?? [];
  const [qno, setQno] = useState(courses.status.completed ? Object.keys(quizList).length : 1);

  const increaseScore = () => {
    setScore(score + (100 / Object.keys(quizList).length));
  };

  const nextQuestion = () => {
    setQno(qno + 1);
  };

  const retake = () => {
    setQno(0);
    setScore(0);
    if (count === 11) {
      setStatusProp(uid ?? '', id ?? '', {
        currentModule: 0,
        completed: false,
        score: {}
      });
    }
  };

  const calculateProgress = () => {
    return (qno * 100) / Object.keys(quizList).length;
  };

  return (
    <div className="container mx-auto p-4 sm:max-w-md lg:max-w-3xl">
      <h1 className="text-xl lg:text-2xl font-bold text-center">{title}</h1>
      <div className="h-2 bg-blue-200 rounded my-2">
        <div className="bg-blue-600 h-full rounded" style={{ width: `${calculateProgress()}%` }}></div>
      </div>
      <div className="text-center text-sm lg:text-base mt-2">
        {qno} of {Object.keys(quizList).length} Questions
      </div>

      {/* Question and Quiz Controls */}
      <div className="mt-4">
        {qno < Object.keys(quizList).length ? (
          <Question key={qno} question={quizList[qno]} increaseScore={increaseScore} nextQuestion={nextQuestion} />
        ) : score < 80 ? (
          <div className="text-center">
            <h3 className="text-red-600 text-lg lg:text-xl">Score: {score}% - Failed</h3>
            <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full sm:w-auto" onClick={retake}>Retake</button>
          </div>
        ) : null}
      </div>

      {/* Conditional Buttons for Quiz Progress */}
      <div className="flex flex-col sm:flex-row justify-center mt-4 space-y-2 sm:space-y-0 sm:space-x-2">
        {(count < 11 && score > 80 && qno === Object.keys(quizList).length) ? (
          <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 w-full sm:w-auto" onClick={() => increaseCountWithScore(score)}>
            Finish and Next
          </button>
        ) : count === 11 && score > 80 && qno === Object.keys(quizList).length && !courses.status.completed ? (
          <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 w-full sm:w-auto" onClick={() => finishCourse(score)}>
            Finish
          </button>
        ) : null}
        {courses.status.completed && (
          <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 w-full sm:w-auto" onClick={handleShow}>
            Generate Certificate
          </button>
        )}
      </div>

      {/* Completion Info */}
      {courses.status.completed && (
        <div className="mt-4 text-center">
          <h2 className="text-lg lg:text-xl font-bold">Congratulations, {name}!</h2>
          <p>Date Completed: {finishDate}</p>
          <p>Valid Through: {expiry}</p>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => ({
  auth: state.auth,
  courses: state.courses,
  user: state.users.user,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  setStatusProp: (uid: string, id: string, status: CourseStatus) => dispatch(setStatus({ uid, id, status })),
  fetchUserProp: (uid: string) => dispatch(fetchUser(uid)),
});


export default connect(mapStateToProps, mapDispatchToProps)(QuizPage);
