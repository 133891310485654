import { useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { connect } from 'react-redux';
import { registerUser, fetchUser } from '../../store/actions/usersActions';
import { AppDispatch } from '../../store';

interface AuthState {
    user: {
        uid: string;
        email: string;
    };
}

interface UserState {
    user: {
        group: string;
    };
}

interface AppState {
    auth: AuthState;
    users: UserState;
}

interface AppProps {
    auth: AuthState;
    users: UserState;
}

interface DispatchProps {
    registerUser: (user: User) => void;
    fetchUser: (uid: string) => void;
}

interface OwnProps {}

type Props = AppProps & OwnProps & DispatchProps;

interface User {
    firstName: string;
    lastName: string;
    managerEmail: string;
    uid: string;
    startDate: string;
    email: string;
    group?: string;
}

const RegisterUser: React.FC<Props> = ({ auth, fetchUser, users, registerUser }) => {
    const { uid, email } = auth.user;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        fetchUser(uid);
    }, [fetchUser, uid]);

    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    };

    const handleManagerEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setManagerEmail(e.target.value);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        setDisabled(true);
        e.preventDefault();
        const user: User = {
            firstName,
            lastName,
            managerEmail,
            uid,
            startDate: new Date().toLocaleDateString(),
            email,
            group: users.user.group,
        };
        registerUser(user);
    };

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">User Registration</h2>
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name:</label>
                    <input
                        required
                        type="text"
                        id="firstName"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name:</label>
                    <input
                        required
                        type="text"
                        id="lastName"
                        value={lastName}
                        onChange={handleLastNameChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="managerEmail" className="block text-sm font-medium text-gray-700">Reporting Manager Email:</label>
                    <input
                        required
                        type="email"
                        id="managerEmail"
                        value={managerEmail}
                        onChange={handleManagerEmailChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <button 
                    type="submit"
                    disabled={disabled}
                    className={`w-full py-2 px-4 rounded-md text-white ${disabled ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'} focus:outline-none focus:ring-2 focus:ring-blue-500`}
                >
                    {disabled ? (
                        <span className="flex items-center justify-center">
                            <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 108 8 8 8 0 00-8-8z"></path>
                            </svg>
                            <span>Loading...</span>
                        </span>
                    ) : (
                        'Register'
                    )}
                </button>
            </form>
        </div>
    );
};

const mapStateToProps = (state: AppState): AppProps => ({
    auth: state.auth,
    users: state.users,
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
    registerUser: (user: User) => dispatch(registerUser(user)),
    fetchUser: (uid: string) => dispatch(fetchUser(uid)),
});

export default connect<AppProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(RegisterUser);
