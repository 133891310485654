import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface AuthState {
  category: string;
}

interface AppState {
  auth: AuthState;
}

const RequireAuth: React.FC = () => {
  const location = useLocation();
  const auth = useSelector((state: AppState) => state.auth);

  if (auth.category === 'LOGIN') {
    return <Navigate to='/signin' state={{ from: location }} />;
  }

  return <Outlet />;
};

export default RequireAuth;