import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CourseState {
  url?: { [key: string]: string };
  status?: Record<string, any>;
  list?: Record<string, any>;
}

const initialState: CourseState = {};

const courseReducer = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setUrl(state, action: PayloadAction<{ [key: string]: string }>) {
      state.url = action.payload;
    },
    setStatusRecord(state, action: PayloadAction<Record<string, any>>) {      
      state.status = action.payload;
    },
    setList(state, action: PayloadAction<Record<string, any>>) {
      state.list = action.payload;
    },
  },
});

export const { setUrl, setStatusRecord, setList } = courseReducer.actions;
export default courseReducer.reducer;