import { ReactNode } from 'react';
import { NavLink, To } from 'react-router-dom';

interface OwnProps {
  to: To;
  children: ReactNode;
  title: string;
}

type Props = OwnProps;

const CardIcon: React.FC<Props> = ({ to, children, title }) => {
  return (
    <div className="m-2 bg-white shadow-lg rounded-lg overflow-hidden">
      <NavLink
        to={to}
        className="flex flex-col items-center p-4 hover:bg-blue-100 hover:text-blue-600 transition-colors duration-200"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon-container m-1 text-gray-500 hover:text-blue-600 transition-colors duration-200"
          width="3.5rem"
          height="3.5rem"
          viewBox="0 0 16 16"
        >
          {children}
        </svg>
        <h4 className="text-lg font-semibold text-center mt-2">{title}</h4>
      </NavLink>
    </div>
  );
};

export default CardIcon;
