import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  id: string;
  name: string;
  email: string;
  group: string;
}

export interface UserState {
  users?: User[];
  user?: User;
  userError?: string;
  message?: string;
}

const initialState: UserState = {};

const usersReducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserError(state, action: PayloadAction<string>) {
      state.userError = action.payload;
    },
    setUsers(state, action: PayloadAction<User[]>) {
      state.users = action.payload;
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setUserMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
  }
});

export const { setUserError, setUsers, setUser, setUserMessage } = usersReducer.actions;
export default usersReducer.reducer;