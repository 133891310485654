import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { filterGroups, fetchGroups, deleteGroup } from "../../store/actions/groupsActions";
import GroupSummary from "./GroupSummary";
import { AppDispatch } from "../../store";

interface Group {
  name: string;
  description: string;
  id: string;
}

interface AppState {
  groups: {
    list: Record<string, Group>;
    message?: string;
  };
}

interface AppProps {
  groups: {
    list: Record<string, Group>;
    message?: string;
  };
}

interface OwnProps {}

interface DispatchProps {
  filterGroups: (search: string) => void;
  fetchGroups: () => void;
  deleteGroup: (group: Group) => void;
}

type Props = AppProps & OwnProps & DispatchProps;

const GroupsPage: React.FC<Props> = ({ groups, filterGroups, fetchGroups, deleteGroup }) => {
  const keys = Object.keys(groups?.list || {});
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (search.length) {
      filterGroups(search);
    } else {
      fetchGroups();
    }
  }, [search, filterGroups, fetchGroups]);

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="hidden md:block text-xl font-bold">GROUPS ({keys.length})</h2>
        <div className="flex items-center">
          <NavLink className="mr-2" to="/group/create">
            <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600">
              Add Group
            </button>
          </NavLink>
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              className="border border-gray-300 rounded-md py-2 px-4"
            />
          </div>
        </div>
      </div>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-200 text-gray-600 text-left">
            <th className="py-2 px-4 border-b text-left">Name</th> {/* Changed to text-left */}
            <th className="py-2 px-4 border-b text-left">Description</th> {/* Changed to text-left */}
            <th className="py-2 px-4 border-b text-left">Actions</th> {/* Changed to text-left */}
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? (
            <tr>
              <td colSpan={3} className="text-center py-4">No Records Available</td>
            </tr>
          ) : (
            keys.map((id) => (
              <GroupSummary key={id} group={groups.list[id]} deleteGroup={deleteGroup} />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  groups: state.groups,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  filterGroups: (search: string) => dispatch(filterGroups(search)),
  fetchGroups: () => dispatch(fetchGroups()),
  deleteGroup: (group: Group) => dispatch(deleteGroup(group)),
});

export default connect<AppProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(GroupsPage));
