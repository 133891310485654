import { ReactNode } from 'react';
import { NavLink, To } from 'react-router-dom';

interface OwnProps {
  to: To;
  children: ReactNode;
  title: string;
}

const CardIcon: React.FC<OwnProps> = ({ to, children, title }) => {
  return (
    <div className="bg-white shadow-md rounded-md w-40 p-2 m-2">
      <NavLink to={to} aria-label={title} className="flex flex-col items-center text-center text-gray-800 hover:text-blue-600 transition duration-200">
        <div className="p-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="w-12 h-12">
            {children}
          </svg>
        </div>
        <h4 className="text-sm font-sans font-semibold text-black mt-2">
          {title}
        </h4>
      </NavLink>
    </div>
  );
};

export default CardIcon;
