import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { GiCyberEye } from "react-icons/gi";
import CardIcon from './CardIcon';
import { NavLink } from "react-router-dom";
import { getCourses } from "../../store/actions/courseActions";
import { AppDispatch } from "../../store";

interface Course {
  id: string;
  name: string;
}

interface AppState {
  courses: {
    list: Record<string, Course>;
  };
}

interface StateProps {
  courses: {
    list: Record<string, Course>;
  };
}

interface DispatchProps {
  getCourses: (search: string) => void;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const CoursePage: React.FC<Props> = ({ getCourses, courses }) => {
  const [search, setSearch] = useState('');

  const handleGetCourses = useCallback(() => {
    getCourses(search);
  }, [getCourses, search]);

  useEffect(() => {
    handleGetCourses();
  }, [handleGetCourses]);

  const courseList = useMemo(() => courses.list || {}, [courses.list]);

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="hidden md:block">COURSES ({Object.keys(courseList).length || 0})</h2>
        <div className="flex items-center">
          {/* <NavLink className="m-1" to='/courses/create'>
            <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-blue-600 transition duration-200">
              Add Course
            </button>
          </NavLink> */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              className="border border-gray-300 rounded py-2 px-4 m-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {Object.keys(courseList).map((course: string, i: number) => (
          <CardIcon to={`/course/${courseList[course].id}`} title={courseList[course].name} key={i}>
            <GiCyberEye />
          </CardIcon>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  courses: state.courses,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCourses: (search: string) => dispatch(getCourses(search)),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(CoursePage);
