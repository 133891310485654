import { db, functions, auth } from "../../config/fbConfig";
import { ref, child, set, onValue, remove } from 'firebase/database';
import { httpsCallable } from 'firebase/functions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUserMessage, setUser, setUsers } from "../reducers/usersReducer";
import { RootState } from '../../store'; // Import RootState

const usersRef = child(ref(db), 'users');
const groupsRef = child(ref(db), 'groups');

const fetchGroupUsers = async (groupId: string) => {
  const groupRef = child(groupsRef, `${groupId}/users`);
  return new Promise<any[]>((resolve) => {
    onValue(groupRef, (snapshot) => {
      resolve(snapshot.val() || []);
    }, { onlyOnce: true });
  });
};

const fetchUserById = async (uid: string) => {
  const userRef = child(usersRef, uid);
  return new Promise<any>((resolve) => {
    onValue(userRef, (snapshot) => {
      resolve(snapshot.val());
    }, { onlyOnce: true });
  });
};

export const createUser = createAsyncThunk('users/createUser', async (user: any, { dispatch }) => {
  try {
    const sendInviteEmail = httpsCallable(functions, 'sendInviteEmailFunction');
    const result: any = await sendInviteEmail({
      email: user.email,
      name: `${user.firstName} ${user.lastName}`
    });
    const userRef = child(usersRef, result.data.uid);
    const groupRef = child(groupsRef, `${user.group}/users`);
    await set(userRef, { ...user, uid: result.data.uid });
    const users = await fetchGroupUsers(user.group);
    users.push(result.data.uid);
    await set(groupRef, users);
    dispatch(setUserMessage('success'));
  } catch (err: any) {
    dispatch(setUserMessage('error'));
  }
});

export const updateUser = createAsyncThunk('users/updateUser', async (user: any, { dispatch }) => {
  const userRef = child(usersRef, user.uid);
  const existingUser = await fetchUserById(user.uid);
  if (existingUser.group !== user.group) {
    const oldGroupUsers = await fetchGroupUsers(existingUser.group);
    const newGroupUsers = await fetchGroupUsers(user.group);
    const updatedOldGroupUsers = oldGroupUsers.filter((uid: string) => uid !== user.uid);
    newGroupUsers.push(user.uid);
    await set(child(groupsRef, `${existingUser.group}/users`), updatedOldGroupUsers);
    await set(child(groupsRef, `${user.group}/users`), newGroupUsers);
  }
  await set(userRef, user);
});

export const registerUser = createAsyncThunk('users/registerUser', async (user: any, { dispatch }) => {
  const registerUser = httpsCallable(functions, 'registerUser');
  const userRef = child(usersRef, user.uid);
  await set(userRef, user);
  await registerUser({ uid: user.uid });
  auth.signOut();
  dispatch(setUserMessage('success'));
});

export const clearUserMessage = createAsyncThunk('users/clearUserMessage', async (_, { dispatch }) => {
  dispatch(setUserMessage(''));
});

export const fetchUsers = createAsyncThunk('users/fetchUsers', async ({ search, status }: { search: string, status: any }, { dispatch, getState }) => {
  const state = getState() as RootState;
  const filterUsers = (users: any, search: string) => {
    return Object.fromEntries(
      Object.entries(users).filter(([_, val]: [string, any]) =>
        `${val.firstName} ${val.lastName} ${val.email}`.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const handleStatusFilter = (users: any, status: string) => {
    switch (status) {
      case 'NOT STARTED':
        return Object.fromEntries(Object.entries(users).filter(([_, val]: [string, any]) => !(val.status && val.status.cyberSecurity) || val.status.cyberSecurity.currentVideo === 0));
      case 'IN PROGRESS':
        return Object.fromEntries(Object.entries(users).filter(([_, val]: [string, any]) => val.status && val.status.cyberSecurity && val.status.cyberSecurity.currentVideo !== 0 && !val.status.cyberSecurity.completed));
      case 'PASS':
        return Object.fromEntries(Object.entries(users).filter(([_, val]: [string, any]) => val.status && val.status.cyberSecurity.completed));
      case 'FAIL':
        return Object.fromEntries(Object.entries(users).filter(([_, val]: [string, any]) => val.status && val.status.cyberSecurity.retake && !val.status.cyberSecurity.completed));
      default:
        return users;
    }
  };

  if (state.auth.user && state.auth.category === "SUPER_ADMIN") {
    onValue(usersRef, (snapshot) => {
      const users = snapshot.val() || {};
      const filteredUsers = filterUsers(users, search);
      const statusFilteredUsers = handleStatusFilter(filteredUsers, status);
      dispatch(setUsers(statusFilteredUsers));
    }, { onlyOnce: true });
  } else if (state.auth.user && state.auth.category === "ADMIN") {
    const user = await fetchUserById(state.auth.user.uid);
    const usersList = await fetchGroupUsers(user.group);
    const users: { [key: string]: any } = {};
    await Promise.all(usersList.map(async (uid: string) => {
      const user = await fetchUserById(uid);
      if (user) users[uid] = user;
    }));
    const filteredUsers = filterUsers(users, search);
    const statusFilteredUsers = handleStatusFilter(filteredUsers, status);
    dispatch(setUsers(statusFilteredUsers));
  }
});

export const fetchUser = createAsyncThunk('users/fetchUser', async (uid: string, { dispatch }) => {
  const user = await fetchUserById(uid);
  dispatch(setUser(user));
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (user: any, { dispatch }) => {
  const removeRef = child(usersRef, user.uid);
  const deleteAccount = httpsCallable(functions, 'deleteAccountFunction');
  await remove(removeRef);
  await deleteAccount({ uid: user.uid });
  await set(child(child(ref(db), 'deletedUsers'), user.uid), user);
  const users = await fetchGroupUsers(user.group);
  const updatedUsers = users.filter((uid: string) => uid !== user.uid);
  await set(child(groupsRef, `${user.group}/users`), updatedUsers);
});

export const batchCreateUsers = createAsyncThunk('users/batchCreateUsers', async ({ userList, groupId }: { userList: any, groupId: string }, { dispatch }) => {
  const sendBatchInviteEmail = httpsCallable(functions, 'sendBatchInviteEmailFunction');
  await sendBatchInviteEmail({ userList, groupId });
  dispatch(setUserMessage('success'));
});