import { combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit'
import { thunk } from "redux-thunk";
import authReducer from "./reducers/authReducer";
import courseReducer from "./reducers/courseReducer"
import usersReducer from "./reducers/usersReducer";
import groupsReducer from "./reducers/groupsReducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
    auth: authReducer,
    courses: courseReducer,
    users: usersReducer,
    groups: groupsReducer
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (gmw) => gmw({serializableCheck: false}).concat(thunk)
})

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch; 
export type RootState = ReturnType<typeof store.getState>;
export type AppState = ReturnType<typeof rootReducer>;
export default store;