import { createAsyncThunk } from '@reduxjs/toolkit';
import { db } from "../../config/fbConfig";
import { ref, child, set, onValue, remove, push } from 'firebase/database';
import { Group, setGroups, setGroup, setGroupMessage, setGroupError } from '../reducers/groupsReducer';

const groupsRef = child(ref(db), 'groups');

const handleFirebaseError = (err: any, dispatch: any) => {
  dispatch(setGroupError(err.message));
};

export const createGroup = createAsyncThunk('groups/createGroup', async (group: Group, { dispatch }) => {
  try {
    const newGroupRef = await push(groupsRef, group);
    await set(child(newGroupRef,'id'), newGroupRef.key);
    dispatch(setGroupMessage('Group created successfully'));
  } catch (err: any) {
    
    handleFirebaseError(err, dispatch);
  }
});

export const clearMessage = createAsyncThunk('groups/clearMessage', async (_, { dispatch }) => {
  dispatch(setGroupMessage(''));
});

export const fetchGroups = createAsyncThunk('groups/fetchGroups', async (_, { dispatch }) => {
  onValue(groupsRef, (snapshot) => {
    const groups = snapshot.exists() ? snapshot.val() : {};
    dispatch(setGroups(groups));
  });
});

export const fetchGroup = createAsyncThunk('groups/fetchGroup', async (id: string, { dispatch }) => {
  const groupRef = child(groupsRef, id);
  onValue(groupRef, (snapshot) => {
    const group = snapshot.exists() ? snapshot.val() : {};
    dispatch(setGroup(group));
  });
});

export const filterGroups = createAsyncThunk('groups/filterGroups', async (search: string, { dispatch }) => {
  onValue(groupsRef, (snapshot) => {
    const groups = snapshot.exists() ? snapshot.val() : {};
    const filteredGroups = Object.entries(groups)
      .filter(([_, val]: [string, any]) =>
        `${val.name} ${val.description}`.toLowerCase().includes(search.toLowerCase())
      )
      .map(([_, val]: [string, any]) => val);
    dispatch(setGroups(filteredGroups));
  });
});

export const deleteGroup = createAsyncThunk('groups/deleteGroup', async (group: Group , { dispatch }) => {
  try {
    const removeRef = child(groupsRef, group.id);
    await remove(removeRef);
    await set(child(ref(db), `deletedGroups/${group.id}`), group);
    dispatch(setGroupMessage('Group deleted successfully'));
  } catch (err: any) {
    handleFirebaseError(err, dispatch);
  }
});