import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface AuthState {
  category: string;
}

interface AppState {
  auth: AuthState;
}

const RequireAdmin: React.FC = () => {
  const location = useLocation();
  const auth = useSelector((state: AppState) => state.auth);

  if (auth.category === 'ADMIN' || auth.category === 'SUPER_ADMIN') {
    return <Outlet />;
  }

  return <Navigate to='/' state={{ from: location }} />;
};

export default RequireAdmin;