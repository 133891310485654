import React, { useState, ChangeEvent, FormEvent } from "react";

interface QuestionProps {
  question: {
    question: string;
    options: string[];
    answer: string;
  };
  increaseScore: () => void;
  nextQuestion: () => void;
}

const Question: React.FC<QuestionProps> = ({ question, increaseScore, nextQuestion }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedOption === question.answer) {
      increaseScore();
    }
    nextQuestion();
  };

  return (
    <form onSubmit={handleSubmit} className="bg-gray-100 rounded-lg p-5">
      <h4 className="font-sans text-xl text-gray-800 text-center mb-4">{question.question}</h4>
      {question.options.map(option => (
        <div key={option} className="mb-3">
          <input
            type="radio"
            id={option}
            name="question"
            value={option}
            checked={selectedOption === option}
            onChange={handleOptionChange}
            className="mr-2"
          />
          <label htmlFor={option} className="cursor-pointer text-gray-700">{option}</label>
        </div>
      ))}
      <button type="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded transition duration-200">
        Submit
      </button>
    </form>
  );
};

export default Question;
