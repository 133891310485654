import React, { useEffect, useState } from "react";
import CardIcon from './CardIcon';
import { connect } from 'react-redux';
import { FaUser, FaUsers, FaBook, FaChartBar } from 'react-icons/fa'; // Import modern icons

interface AppState {
  auth: any;
}

interface StateProps {
  auth: any;
}

interface OwnProps {}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const Dashboard: React.FC<Props> = ({ auth }) => {
  const { category } = auth;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (category && category !== 'LOGIN') {
      setLoading(false);
    }
  }, [category]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <svg
          className="animate-spin h-5 w-5 text-blue-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0114.75-4.003l1.496 1.23A10.005 10.005 0 0024 12h-4a8 8 0 01-16 0h4z"></path>
        </svg>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Welcome to the LMS Dashboard</h1>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {(category === 'ADMIN' || category === 'SUPER_ADMIN') && (
          <>
            <div>
              <CardIcon to='/users' title="Users">
                <FaUser />
              </CardIcon>
            </div>
            <div>
              <CardIcon to='/reports' title="Reports">
                <FaChartBar />
              </CardIcon>
            </div>
          </>
        )}
        <div>
          <CardIcon to='/courses' title="Courses">
            <FaBook />
          </CardIcon>
        </div>
        {category === 'SUPER_ADMIN' && (
          <div>
            <CardIcon to='/groups' title="Groups">
              <FaUsers />
            </CardIcon>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps)(React.memo(Dashboard));
