import React from 'react';
import { Link } from "react-router-dom";

const SuperAdminLinks: React.FC = () => {
  return (
    <> {/* Changed from flex-col to flex and added space-x-4 */}
      <Link 
        to='/' 
        className="text-gray-800 hover:text-blue-600 transition duration-200 py-2 px-4 rounded-md"
      >
        Dashboard
      </Link>
      <Link 
        to='/groups' 
        className="text-gray-800 hover:text-blue-600 transition duration-200 py-2 px-4 rounded-md"
      >
        Groups
      </Link>
      <Link 
        to='/reports' 
        className="text-gray-800 hover:text-blue-600 transition duration-200 py-2 px-4 rounded-md"
      >
        Reports
      </Link>
      <Link 
        to='/users' 
        className="text-gray-800 hover:text-blue-600 transition duration-200 py-2 px-4 rounded-md"
      >
        Users
      </Link>
      <Link 
        to='/courses' 
        className="text-gray-800 hover:text-blue-600 transition duration-200 py-2 px-4 rounded-md"
      >
        Courses
      </Link>
    </>
  );
}

export default SuperAdminLinks;
