declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (siteKey: string, options: { action: string }) => Promise<string>;
    };
  }
}

export const loadReCaptcha = async (siteKey: string, action: string): Promise<string> => {
  try {
    await new Promise<void>((resolve) => window.grecaptcha.ready(resolve));
    const token = await window.grecaptcha.execute(siteKey, { action });
    return token;
  } catch (error) {
    throw error;
  }
};