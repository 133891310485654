import React, { useState, ChangeEvent } from 'react';
import { connect } from 'react-redux';
// @ts-ignore
import { PencilIcon } from '@heroicons/react/24/solid';

interface User {
  firstName: string;
  email: string;
  startDate: string;
  lastName: string;
  managerEmail: string;
  group?: string;
  uid: string;
}

interface OwnProps {
  user: User;
  updateUser: (user: User) => void;
}

interface StateProps {
  groups: any;
}

interface AppState {
  groups: any;
}

interface DispatchProps {}

type Props = DispatchProps & OwnProps & StateProps;

const EditUser: React.FC<Props> = ({ user, groups, updateUser }) => {
  const [show, setShow] = useState(false);
  const [editUser, setEditUser] = useState<User>(user);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setEditUser((prevUser) => ({ ...prevUser, [id]: value }));
  };

  return (
    <>
      <button
        className="text-gray-500 hover:text-blue-700 rounded-full focus:outline-none"
        onClick={handleShow}
      >
        <PencilIcon className="h-6 w-6" /> {/* Using Heroicons Pencil Icon */}
      </button>

      {show && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50 p-4">
          <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 w-full max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-bold">Edit User</h2>
              <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
                &times;
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name:</label>
                <input
                  required
                  type="text"
                  id="firstName"
                  value={editUser.firstName}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name:</label>
                <input
                  required
                  type="text"
                  id="lastName"
                  value={editUser.lastName}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label htmlFor="managerEmail" className="block text-sm font-medium text-gray-700">Reporting Manager Email:</label>
                <input
                  required
                  type="email"
                  id="managerEmail"
                  value={editUser.managerEmail}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              {groups.list && (
                <div>
                  <label htmlFor="group" className="block text-sm font-medium text-gray-700">Group:</label>
                  <select
                    id="group"
                    onChange={handleChange}
                    value={editUser.group || ''}
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Group</option>
                    {Object.keys(groups.list).map((gId: string) => (
                      <option key={gId} value={gId}>
                        {groups.list[gId].name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="flex justify-end mt-6 space-x-2">
              <button 
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
                onClick={handleClose}
              >
                Close
              </button>
              <button 
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                onClick={() => { updateUser(editUser); handleClose(); }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  groups: state.groups,
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps)(EditUser);
