import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createCourse } from '../../store/actions/courseActions';
import { AppDispatch } from '../../store';

interface Module {
  type: string;
  title: string;
  file: string;
  fileUpload: File;
}

interface Course {
  name: string;
  description: string;
  modules: Module[];
}

interface DispatchProps {
  createCourse: (course: Course) => void;
}

type Props = DispatchProps;

const CreateCourse: React.FC<Props> = ({ createCourse }) => {
  const [course, setCourse] = useState<Course>({ name: '', description: '', modules: [] });
  const [loading, setLoading] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setCourse(prevCourse => ({ ...prevCourse, [id]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    createCourse(course);
    setLoading(false);
    setCourse({ name: '', description: '', modules: [] });
  };

  const handleAddVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileUpload = e.target.files![0];
    const file = fileUpload.name.split('.').slice(0, -1).join('.');
    const newModule: Module = { type: 'video', title: '', file, fileUpload };
    setCourse(prevCourse => ({ ...prevCourse, modules: [...prevCourse.modules, newModule] }));
    setShowVideoModal(false);
  };

  const renderVideoModal = () => (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${showVideoModal ? '' : 'hidden'}`}>
      <div className="bg-white rounded shadow-lg p-6">
        <h3 className="text-lg font-bold mb-4">Add Video Module</h3>
        <div className="mb-4">
          <label className="block mb-2">Video File</label>
          <input type='file' className="border rounded p-2 w-full" onChange={handleAddVideo} />
        </div>
        <div className="flex justify-end">
          <button className="bg-gray-500 text-white px-4 py-2 rounded mr-2" onClick={() => setShowVideoModal(false)}>Close</button>
          <button className="bg-blue-500 text-white px-4 py-2 rounded">Add Video</button>
        </div>
      </div>
    </div>
  );

  const renderQuizModal = () => (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${showQuizModal ? '' : 'hidden'}`}>
      <div className="bg-white rounded shadow-lg p-6">
        <h3 className="text-lg font-bold mb-4">Add Quiz Module</h3>
        <div className="mb-4">
          <label className="block mb-2">Quiz Title</label>
          <input type='text' className="border rounded p-2 w-full" placeholder='Enter quiz title' />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Questions</label>
          <button className="bg-gray-500 text-white px-4 py-2 rounded">Add Question</button>
        </div>
        <div className="flex justify-end">
          <button className="bg-gray-500 text-white px-4 py-2 rounded mr-2" onClick={() => setShowQuizModal(false)}>Close</button>
          <button className="bg-blue-500 text-white px-4 py-2 rounded">Add Quiz</button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Create Course</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2" htmlFor="name">Course Name</label>
          <input
            type='text'
            id="name"
            value={course.name}
            placeholder='Enter course name'
            onChange={handleChange}
            className="border rounded p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2" htmlFor="description">Course Description</label>
          <textarea
            id="description"
            value={course.description}
            rows={3}
            placeholder='Enter course description'
            onChange={handleChange}
            className="border rounded p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Modules</label>
          <button className='bg-gray-500 text-white px-4 py-2 rounded m-1' onClick={() => setShowVideoModal(true)}>Add Video</button>
          <button className='bg-gray-500 text-white px-4 py-2 rounded m-1' onClick={() => setShowQuizModal(true)}>Add Quiz</button>
        </div>
        <table className="min-w-full bg-white border rounded shadow">
          <thead>
            <tr>
              <th className="border px-4 py-2">#</th>
              <th className="border px-4 py-2">Type</th>
              <th className="border px-4 py-2">Title</th>
              <th className="border px-4 py-2">File Name</th>
              <th className="border px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {course.modules.map((module, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{index + 1}</td>
                <td className="border px-4 py-2">{module.type}</td>
                <td className="border px-4 py-2">
                  <input
                    type='text'
                    value={module.title}
                    onChange={(e) => {
                      const updatedModules = [...course.modules];
                      updatedModules[index].title = e.target.value;
                      setCourse({ ...course, modules: updatedModules });
                    }}
                    className="border rounded p-1 w-full"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type='text'
                    value={module.file}
                    onChange={(e) => {
                      const updatedModules = [...course.modules];
                      updatedModules[index].file = e.target.value;
                      setCourse({ ...course, modules: updatedModules });
                    }}
                    className="border rounded p-1 w-full"
                  />
                </td>
                <td className="border px-4 py-2">
                  <button className='bg-red-500 text-white rounded p-1'>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className='bg-blue-500 text-white px-4 py-2 rounded mt-4' type='submit'>
          {loading ? <span className="loader">Loading...</span> : 'Create Course'}
        </button>
      </form>
      {renderVideoModal()}
      {renderQuizModal()}
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createCourse: (course: Course) => dispatch(createCourse(course)),
});

export default connect(null, mapDispatchToProps)(CreateCourse);
