import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
import AdminLinks from './AdminLinks';
import EmployeeLinks from "./EmployeeLinks";
import SuperAdminLinks from "./SuperAdminLinks";
import { signOut } from "../../store/actions/authActions";
import { fetchUser } from '../../store/actions/usersActions';
import { fetchGroup } from '../../store/actions/groupsActions';
import { AppDispatch } from '../../store';

interface AuthState {
  user: {
    uid: string;
    email: string;
  };
  category: string;
}

interface UserState {
  user: {
    group: string;
  };
}

interface GroupsState {
  list: any;
}

interface AppState {
  auth: AuthState;
  users: UserState;
  groups: GroupsState;
}

interface AppProps {
  auth: AuthState;
  users: UserState;
  groups: GroupsState;
}

interface DispatchProps {
  fetchUser: (uid: string) => void;
  fetchGroup: (id: string) => void;
}

interface OwnProps {}

type Props = AppProps & OwnProps & DispatchProps;

const Logo = React.forwardRef<HTMLImageElement, { src: string; alt: string; style?: React.CSSProperties; }>(
  ({ src, alt, style }, ref) => (
    <div className="flex items-center">
      <img
        ref={ref}
        alt={alt}
        src={require(`../../assets/${src}`)}
        style={style}
        className="h-auto max-h-14"
      />
    </div>
  )
);

const NavigationBar: React.FC<Props> = ({ fetchUser, users, fetchGroup, groups }) => {
  const auth = useSelector((state: AppState) => state.auth) as AuthState;
  const dispatch = useDispatch<AppDispatch>();
  const firstLogoRef = useRef<HTMLImageElement | null>(null);
  const [firstLogoHeight, setFirstLogoHeight] = useState<number>(0);
  const { category, user } = auth;

  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for toggle
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // Track visibility of dropdown

  // Function to handle mouse enter on icon or menu
  const handleMouseEnter = () => {
    setIsDropdownVisible(true);
  };

  // Function to handle mouse leave on icon or menu
  const handleMouseLeave = () => {
    setIsDropdownVisible(false);
  };

  useEffect(() => {
    fetchUser(user.uid);
  }, [user.uid, fetchUser]);

  useEffect(() => {
    if (users.user && users.user.group) fetchGroup(users.user.group);
  }, [users.user, fetchGroup]);

  useEffect(() => {
    if (firstLogoRef.current) {
      setFirstLogoHeight(firstLogoRef.current.offsetHeight);
    }
  }, [firstLogoRef.current?.offsetHeight]);

  const links =
    category === 'SUPER_ADMIN' ? <SuperAdminLinks /> :
    category === 'ADMIN' ? <AdminLinks /> :
    category === 'USER' ? <EmployeeLinks /> : null;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu state
  };

  return (
    <nav className="bg-sky-500 sticky top-0 z-50 shadow-lg">
      <div className="container mx-auto flex justify-between items-center py-4">
        {/* Logo Container */}
        <div className="flex items-center space-x-4 md:space-x-8"> {/* Container for logos */}
          <Logo
            ref={firstLogoRef}
            src="Navbar Logo.png"
            alt="logo"
            style={{ width: '75%', height: 'auto', minHeight: '25px' }} // Ensure proper sizing
          />
          <div className="w-px h-10 bg-gray-300 mx-4"></div>
          {users.user && groups.list && groups.list[users.user.group] && (
            <Logo
              src={`${groups.list[users.user.group].name.toLowerCase()}.png`}
              alt="second logo"
              style={{ height: 'auto', width: '75%' }} // Ensure the second logo matches height if needed
            />
          )}
        </div>
        <div className="flex items-center space-x-4">
          {/* Hamburger Icon for Mobile */}
          <div className="md:hidden">
            <button 
              onClick={toggleMenu} 
              className="text-gray-700 hover:text-sky-600"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 2.5A.5.5 0 0 1 1.5 2h13a.5.5 0 0 1 0 1H1.5A.5.5 0 0 1 1 2.5zM1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1H1.5A.5.5 0 0 1 1 8zM1 13.5A.5.5 0 0 1 1.5 13h13a.5.5 0 0 1 0 1H1.5A.5.5 0 0 1 1 13.5z" />
              </svg>
            </button>
          </div>
          <div className={`hidden md:flex space-x-4`}> {/* Show links for desktop */}
            {links}
          </div>
          {category && category !== 'LOGIN' && (
            <nav className="bg-sky-500 sticky top-0 z-50 shadow-lg">
              <div className="relative inline-block text-left">
                {/* User icon button */}
                <button
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="flex items-center justify-center px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                  </svg>
                </button>
      
                {/* Dropdown menu */}
                {isDropdownVisible && (
                  <div
                    className="absolute right-0 z-10 mt-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    onMouseEnter={handleMouseEnter} // Keep dropdown open when mouse is on it
                    onMouseLeave={handleMouseLeave} // Close dropdown when mouse leaves
                  >
                    <div className="py-1" role="none">
                      <button
                        onClick={() => dispatch(signOut())}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      >
                        <i className="bi bi-box-arrow-in-right" /> Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
          </nav>
          )}
        </div>
      </div>
      {/* Show mobile links vertically */}
      {isMenuOpen && (
        <div className="md:hidden bg-white shadow-lg rounded-md p-4"> {/* Add background and padding */}
          <div className="flex flex-col space-y-2"> {/* Vertical layout */}
            {links}
          </div>
        </div>
      )}
    </nav>
  );
};

const mapStateToProps = (state: AppState): AppProps => ({
  auth: state.auth,
  users: state.users,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  fetchUser: (uid: string) => dispatch(fetchUser(uid)),
  fetchGroup: (id: string) => dispatch(fetchGroup(id))
});

export default connect<AppProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(NavigationBar);
