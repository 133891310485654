import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, clearError } from '../../store/actions/authActions';
import { useNavigate } from 'react-router-dom';
import bg from '../../assets/bg.jpg';
import logo from '../../assets/Logo.png';
import { loadReCaptcha } from './recaptcha';
import { RootState, AppDispatch } from '../../store';
import './SignIn.css';

const SignIn: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const authError = useSelector((state: RootState) => state.auth.authError);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(authError);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    captcha: ''
  });

  useEffect(() => {
    setError(authError);
    if (authError) {
      setDisabled(false);
    }
  }, [authError]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [id]: value
    }));
  }, []);

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(clearError());
    setDisabled(true);
    loadReCaptcha('6LfMgegpAAAAAGqxpNpkPi4WRzIwIiOxenNND8Ir', 'submit').then((token) => {
      const updatedCredentials = {
        ...credentials,
        captcha: token
      };
      setCredentials(updatedCredentials);
      dispatch(signIn(updatedCredentials));
      setCredentials((prevCredentials) => ({
        ...prevCredentials,
        password: ''
      }));
    });
  }, [dispatch, credentials]);

  return (
    <div className="signin-container relative w-full h-screen overflow-hidden">
      <img src={bg} alt="background" className="signin-background absolute top-0 left-0 w-full h-full object-cover z-[-1]" />
      <div className="signin-form-container absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 max-w-xs w-full text-center bg-white p-8 shadow-lg rounded-lg">
        <img src={logo} alt="logo" className="mb-4 w-24 h-24 mx-auto" />
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email address</label>
            <input
              type="email"
              id="email"
              placeholder="Enter email"
              value={credentials.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-pink-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              value={credentials.password}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-pink-500"
              required
            />
          </div>
          {error && (
            <div className="mb-4">
              <div className="bg-red-500 text-white px-3 py-2 rounded flex justify-between items-center">
                <span>{error}</span>
                <button onClick={() => dispatch(clearError())} className="text-white">X</button>
              </div>
            </div>
          )}
          <button
            type="submit"
            disabled={disabled}
            className="w-full bg-blue-500 text-white py-2 rounded font-semibold flex justify-center items-center hover:bg-blue-600 transition duration-200"
          >
            {disabled ? (
              <span className="w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin"></span>
            ) : (
              'Sign In'
            )}
          </button>
          <button
            type="button"
            onClick={() => navigate('/reset')}
            className="w-full text-blue-500 hover:underline mt-2"
          >
            Forgot Password?
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
