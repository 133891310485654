import { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NavigationBar from './layout/NavigationBar';
import Dashboard from "./dashboard/Dashboard";
import SignIn from './auth/SignIn';
import Reset from "./auth/Reset";
import RequireAuth from "./auth/RequireAuth";
import RequireAdmin from './auth/RequireAdmin';
import RequireSuperAdmin from './auth/RequireSuperAdmin';
import CoursePage from "./courses/CoursePage";
import CourseContent from "./courses/CourseContent";
import { connect } from "react-redux";
import { stateChange } from "../store/actions/authActions";
import UsersPage from "./users/UsersPage";
import CreateUser from "./users/CreateUser";
import RegisterUser from "./users/RegisterUser";
import RequireRegister from "./auth/RequireRegister";
import GroupsPage from "./groups/GroupsPage";
import CreateGroup from "./groups/CreateGroup";
import CreateCourse from "./courses/CreateCourse";
import { AppDispatch } from "../store";
import ReportsPage from "./reports/ReportsPage";

interface AppState {
  auth: {
    category?: string;
  };
}

interface StateProps {
  auth: AppState['auth'];
}

interface DispatchProps {
  stateChange: () => void;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const App: React.FC<Props> = ({ auth, stateChange }) => {
  const { category } = auth;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuthState = async () => {
      await stateChange();
      setLoading(false);
    };
    initializeAuthState();
  }, [stateChange]);

  const renderRoutes = useCallback(() => (
    <Routes>
      <Route path='/signin' element={category === 'LOGIN' ? <SignIn /> : <Navigate to='/' replace />} />
      <Route path="/reset" element={category === 'LOGIN' ? <Reset /> : <Navigate to='/' replace />} />
      <Route element={<RequireAuth />} >
        <Route path='/register' element={<RegisterUser />} />
        <Route element={<RequireRegister />} >
          <Route path="/" element={<Dashboard />} />
          <Route path="/courses" element={<CoursePage />} />
          <Route path="/courses/create" element={<CreateCourse />} />
          <Route path="/course/:id" element={<CourseContent />} />
          <Route element={<RequireAdmin />} >
            <Route path='/users' element={<UsersPage />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path='/user/create' element={<CreateUser />} />
            <Route element={<RequireSuperAdmin />} >
              <Route path='/groups' element={<GroupsPage />} />
              <Route path='/group/create' element={<CreateGroup />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  ), [category]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid" />
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <div className="App">
        {category !== 'LOGIN' && <NavigationBar />}
        {renderRoutes()}
      </div>
      <div style={{ height: '100px' }}></div>
    </BrowserRouter>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  stateChange: () => dispatch(stateChange())
});

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(App);
