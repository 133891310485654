import React, { useState, useEffect, useCallback } from "react";
import { fetchUsers, deleteUser, updateUser } from "../../store/actions/usersActions";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import UserSummary from "./UserSummary";
import { AppDispatch } from "../../store";

interface AppState {
  users: any;
}

interface StateProps {
  users: any;
}

interface DispatchProps {
  fetchUsers: (search: string, status: string) => void;
  deleteUser: (user: any) => void;
  updateUser: (user: any) => void;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const UsersPage: React.FC<Props> = ({ users, fetchUsers, deleteUser, updateUser }) => {
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('All');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  useEffect(() => {
    fetchUsers(search, status);
    setCurrentPage(1);
  }, [search, status, fetchUsers]);

  const exportData = useCallback(() => {
    setLoading(true);
    const csv = [
      ['First Name', 'Last Name', 'Email', 'Reporting Manager', 'Start Date', 'Progress', 'Status'],
      ...Object.keys(users?.users || {}).map(uid => {
        const user = users.users[uid];
        return [
          user.firstName,
          user.lastName,
          user.email,
          user.reportingManager,
          user.startDate,
          user.status?.currentModule || 0,
          user.status ? (user.status.completed ? 'PASS' : user.status.repeat ? 'FAIL' : 'IN PROGRESS') : 'NOT STARTED'
        ].join(',');
      })
    ];
    const csvContent = "data:text/csv;charset=utf-8," + csv.join("\r\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  }, [users]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const totalUsers = Object.keys(users?.users || {}).length;
  const totalPages = Math.ceil(totalUsers / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const usersToDisplay = Object.keys(users?.users || {}).slice(startIdx, endIdx);

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4 space-y-4 md:space-y-0">
    
    {/* Title */}
    <h2 className="text-xl font-bold mb-4 md:mb-0 md:mr-4 hidden md:block">USERS ({totalUsers})</h2>

    {/* Actions (Export and Add User) */}
    <div className="flex space-x-2 md:space-x-4">
      <button
        onClick={exportData}
        className={`px-4 py-2 text-white bg-blue-500 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={loading}
      >
        {loading ? 'Exporting...' : 'Export'}
      </button>
      <NavLink to='/user/create'>
        <button className="px-4 py-2 text-white bg-blue-500 rounded">Add User</button>
      </NavLink>
    </div>

    {/* Filters (Status and Search) */}
    <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-2 w-full md:w-auto">
      <select
        onChange={(e) => setStatus(e.target.value)}
        value={status}
        className="border rounded p-2 md:w-auto w-full"
      >
        <option value='ALL'>All</option>
        <option value='NOT STARTED'>Not Started</option>
        <option value='IN PROGRESS'>In Progress</option>
        <option value='PASS'>Pass</option>
        <option value='FAIL'>Fail</option>
      </select>
      <input
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        className="border rounded p-2 md:w-auto w-full"
      />
    </div>
  </div>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-200 text-gray-600 text-left">
            <th className="p-2 border border-gray-300 sm:hidden">User Info</th>
            <th className="p-2 border border-gray-300 hidden sm:table-cell">User Email</th>
            <th className="p-2 border border-gray-300 hidden md:table-cell">First Name</th>
            <th className="p-2 border border-gray-300 hidden md:table-cell">Last Name</th>
            <th className="p-2 border border-gray-300 hidden lg:table-cell">Reporting Manager</th>
            <th className="p-2 border border-gray-300 hidden lg:table-cell">Start Date</th>
            <th className="p-2 border border-gray-300">Progress</th>
            <th className="p-2 border border-gray-300">Actions</th>
          </tr>
        </thead>
        <tbody>
          {usersToDisplay.length === 0 ? (
            <tr><td colSpan={7} className="text-center p-4">No Records Available</td></tr>
          ) : (
            usersToDisplay.map(uid => (
              <UserSummary key={uid} user={users.users[uid]} deleteUser={deleteUser} updateUser={updateUser} />
            ))
          )}
        </tbody>
      </table>

      <div className="flex justify-center mt-4">
        <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} className={`mx-1 px-4 py-2 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}>First</button>
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className={`mx-1 px-4 py-2 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}>Prev</button>

        {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
          let pageNumber: number;
          if (currentPage <= 3 || totalPages <= 5) {
            pageNumber = i + 1;
          } else if (currentPage > totalPages - 3) {
            pageNumber = totalPages - 4 + i;
          } else {
            pageNumber = currentPage - 2 + i;
          }

          return (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`mx-1 px-4 py-2 ${pageNumber === currentPage ? 'font-bold' : ''}`}
            >
              {pageNumber}
            </button>
          );
        })}

        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className={`mx-1 px-4 py-2 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}>Next</button>
        <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} className={`mx-1 px-4 py-2 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}>Last</button>
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => state;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsers: (search: string, status: string) => dispatch(fetchUsers({ search, status })),
  deleteUser: (user: any) => dispatch(deleteUser(user)),
  updateUser: (user: any) => dispatch(updateUser(user)),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(UsersPage);
