import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { fetchUsers } from '../../store/actions/usersActions';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart, ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { AppDispatch } from "../../store";
import { getCourses } from '../../store/actions/courseActions';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import certificate from './certificate.jpg';
import FontFaceObserver from "fontfaceobserver";
import { fetchGroups } from '../../store/actions/groupsActions';

Chart.register(ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

interface AppState {
  auth: any;
  user: any;
  users: any;
  courses: any;
  groups: any;
}

interface StateProps {
  auth: any;
  user: any;
  users: any;
  courses: any;
  groups: any;
}

interface OwnProps {}

interface DispatchProps {
  fetchUsers: (search: string, status: string) => void;
  getCourses: (search: string) => void;
  fetchGroups: () => void;
}

type Props = DispatchProps & StateProps & OwnProps;

const ReportsView: React.FC<Props> = ({users, fetchUsers, getCourses, courses, user, groups, fetchGroups, auth}) => {
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('ALL');
  const [groupUsers, setGroupUsers] = useState(users);
  const [date, setDate] = useState(new Date());
  const [disabled, setDisabled] = useState(false);
  const handleGetCourses = useCallback(() => {
    getCourses('');
  }, [getCourses]);

  useEffect(() => {
    handleGetCourses();
  }, [handleGetCourses]);

  useEffect(() => {
    fetchUsers('', 'All');
  }, [fetchUsers]);

  useEffect(() => {
    if (selectedGroup !== 'ALL') {
      const filteredUsers = Object.values(users).filter((user: any) => user.group === selectedGroup);
      setGroupUsers(filteredUsers);
    } else {
      setGroupUsers(users);
    }
  }, [users, selectedGroup]);

  useEffect(() => {
    if (auth.category === "SUPER_ADMIN") fetchGroups();
  }, [fetchGroups, auth]);

  const exportCompletedCertificates = async (course: any) => {
    setLoading(true);
    const zip = new JSZip();
  
    const generateImage = async (name: string, startDate: string, endDate: string, groupName: string): Promise<string> => {
      const font = new FontFaceObserver('Italianno');
      await font.load();
  
      const img = new Image();
      img.src = certificate;
  
      await new Promise<void>((resolve, reject) => {
        img.onload = () => resolve();
        img.onerror = () => reject(new Error('Certificate image failed to load.'));
      });
  
      const logoSrc = await import(`../../assets/${groupName.toLowerCase()}.png`);
      const logo = new Image();
      logo.src = logoSrc.default;
  
      await new Promise<void>((resolve, reject) => {
        logo.onload = () => resolve();
        logo.onerror = () => reject(new Error('Logo image failed to load.'));
      });
  
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
  
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const relativeHeight = 70;
        const relativeWidth = logo.width / logo.height * relativeHeight;
        ctx.drawImage(logo, img.width - relativeWidth - 85, img.height - relativeHeight - 45, relativeWidth, relativeHeight);
  
        ctx.font = '140px Italianno';
        ctx.fillStyle = 'darkblue';
        ctx.textAlign = 'center';
        ctx.fillText(name, canvas.width / 2, 615);
  
        ctx.font = '40px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(startDate, canvas.width / 4, 860);
        ctx.fillText(endDate, (canvas.width * 3) / 4, 860);
      }
  
      return canvas.toDataURL();
    };
  
    // Filter users who have completed the course
    const completedUsers = Object.values(groupUsers).filter((user: any) => user.status?.cyberSecurity?.completed);
    
    // Generate certificates and add to zip
    const certificatePromises = completedUsers.map(async (user: any) => {
      const dataUrl = await generateImage(
        `${user.firstName} ${user.lastName}`,
        user.status.cyberSecurity.dateCompleted,
        user.status.cyberSecurity.validThrough,
        groups.list[user.group].name
      );
      const blob = await (await fetch(dataUrl)).blob();
      zip.file(`${user.firstName}_${user.lastName}_Cybersecurity.png`, blob);
    });
  
    // Wait for all certificates to be generated and added to the zip
    await Promise.all(certificatePromises);
  
    if (completedUsers.length === 0) {
      alert("No users have completed this course.");
      return;
    }
  
    // Generate and download the zip file after all certificates are added
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, `Cybersecurity_Completed_Certificates.zip`);
    setLoading(false);
  };
  
  const getStatusCounts = () => {
    const counts = { completed: 0, inProgress: 0, notStarted: 0 };
    groupUsers && Object.values(groupUsers).forEach((user: any) => {
      const { cyberSecurity } = user.status || {};
      if (cyberSecurity?.completed) {
        counts.completed += 1;
      } else if (cyberSecurity?.currentModule > 0) {
        counts.inProgress += 1;
      } else {
        counts.notStarted += 1;
      }
    });
    return counts;
  };

  const getVideoProgressCounts = () => {
    const videoCounts: { [key: number]: number } = {};
    groupUsers && Object.values(groupUsers).forEach((user: any) => {
      const video = user.status?.cyberSecurity?.currentModule || 0;
      videoCounts[video] = (videoCounts[video] || 0) + 1;
    });
    return videoCounts;
  };

  const statusCounts = getStatusCounts();
  const videoProgressCounts = getVideoProgressCounts();

  const pieData = {
    labels: ['Completed', 'In Progress', 'Not Started'],
    datasets: [{
      data: [statusCounts.completed, statusCounts.inProgress, statusCounts.notStarted],
      backgroundColor: ['#28a745', '#ffc107', '#dc3545'],
    }],
  };

  const barData = {
    labels: Object.keys(videoProgressCounts),
    datasets: [{
      label: 'Users per Video Progress',
      data: Object.values(videoProgressCounts),
      backgroundColor: '#007bff',
    }],
  };

  return (
    <div className="container mx-auto p-4">
      <div className="reports-view">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl">Users Report ({Object.keys(groupUsers || {}).length || 0} Users)</h2>
          {auth.category === 'SUPER_ADMIN' && 
            <select 
              className="form-select border-gray-300 rounded-md" 
              onChange={(e) => setSelectedGroup(e.target.value)} 
              value={selectedGroup}
            >
              <option value='ALL'>All</option>
              {Object.keys(groups.list).map(gid => (
                <option key={gid} value={gid}>{groups.list[gid].description}</option>
              ))}
            </select>
          }
        </div>
        <div className="flex flex-wrap justify-around mt-5">
          <div className="w-full md:w-1/2 p-2">
            <h4 className="text-lg">User Status Distribution</h4>
            <Pie data={pieData} />
          </div>
          <div className="w-full md:w-1/2 p-2">
            <h4 className="text-lg">CyberSecurity Video Progress</h4>
            <Bar data={barData} options={{ scales: { y: { beginAtZero: true } } }} />
          </div>
        </div>
        <table className="min-w-full mt-8 border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 p-2">Course Name</th>
              <th className="border border-gray-300 p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {groupUsers && courses && Object.keys(courses).map((courseId: any) => (
              <tr key={courseId}>
                <td className="border border-gray-300 p-2">{courses[courseId].name}</td>
                <td className="border border-gray-300 p-2">
                  <button 
                    className={`bg-blue-500 text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} 
                    onClick={() => exportCompletedCertificates(courses[courseId])} 
                    disabled={loading}
                  >
                    {loading ? 'Exporting...' : 'Export Completed Certificates'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  courses: state.courses.list,
  user: state.users.user,
  users: state.users.users,
  groups: state.groups,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsers: (search: string, status: string) => dispatch(fetchUsers({search, status})),
  getCourses: (search: string) => dispatch(getCourses(search)),
  fetchGroups: () => dispatch(fetchGroups()),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(ReportsView);
