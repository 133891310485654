import React, { useState } from 'react';
// @ts-ignore
import { TrashIcon } from '@heroicons/react/24/solid';

interface User {
  email: string;
  firstName: string;
  lastName: string;
  uid: string;
}

interface OwnProps {
  user: User;
  deleteUser: (user: User) => void;
}

type Props = OwnProps;

const DeleteUser: React.FC<Props> = ({ user, deleteUser }) => {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  return (
    <>
      <button
        className="text-gray-500 hover:text-red-700 rounded-full focus:outline-none"
        onClick={toggleModal}
      >
        <TrashIcon className="h-6 w-6" /> {/* Using Heroicons Trash Icon */}
      </button>
      
      {show && (
        <>
          {/* Overlay with lower z-index to allow clicking on modal */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>

          {/* Modal container with higher z-index */}
          <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-0">
              <div className="flex justify-between items-center border-b p-4">
                <h3 className="text-lg font-semibold">Delete User?</h3>
                <button onClick={toggleModal} className="text-gray-500 hover:text-gray-700">
                  &times;
                </button>
              </div>
              <div className="p-4">
                <p className="text-gray-700">Email: {user.email}</p>
                <p className="text-gray-700">Name: {user.firstName} {user.lastName}</p>
              </div>
              <div className="flex justify-end p-4 border-t space-x-2">
                <button 
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
                  onClick={toggleModal}
                >
                  Close
                </button>
                <button 
                  className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700"
                  onClick={() => { deleteUser(user); toggleModal(); }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DeleteUser;
